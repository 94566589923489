import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import MultiSelectDropdown from '../mutliSelectDropdown';
import actions from '../../redux/actions';
import PageTitle from '../PageTitle';
import missedOpportunityOutletModel from '../../pages/missed-opportunity-outlet';
import utils from '../../utils'
import { useTranslation } from 'react-i18next';
import { apis } from '../../httpUtil';
import { transport } from '../../httpUtil/httpRequest';

const cardsToRender = [
    { key: "ActualRevenue", title: "Actual Revenue", value: 'actualRevenue', addCurrencySymbol: true, ml: 25 },
    { key: "MissedRevenue", title: "Missed Revenue", value: 'missedRevenue', addCurrencySymbol: true, ml: 25 },
    { key: "UpliftPotential", title: "Uplift Potential", value: 'upliftPotential', ml: 25, addPercentageSymbol: true },
    { key: "AverageDailyUnitsSoldPerAsset", title: "Avg Daily Units Sold Per Asset", value: 'averageUnitsSold', ml: 60 },
    { key: "AverageDaysSKUOutOfStockPerAsset", title: "Avg Days SKU OOS Per Product", value: 'averageSKU', ml: 60 },
    { key: "TotalAssetsMonitored", title: "Total Assets Monitored", value: 'uniqueAssetsCount', ml: 50 }
]

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const config = {
    label: "Clients",
    titleMessage: 'Select & Unselect All',
    multiSelectLabel: 'Multiple Clients',
}

const HorizontalBars = React.memo(function HorizontalBars() {
    const userData = useSelector(state => state.appReducer.userData) || {};
    const { ClientId, CurrencySymbol } = userData?.tags || {};
    const [selectedClients, setSelectedClients] = React.useState([Number(ClientId)]);
    const [activeClients, setActiveClients] = React.useState([Number(ClientId)]);
    const [dateDiff, setDateDiff] = React.useState(30);
    const [dateValue, setDateValue] = React.useState([dayjs().subtract(30, 'day'), dayjs()]);
    const [gridFilters, setGridFilters] = React.useState([]);
    const [cardValues, setCardValues] = React.useState({ actualRevenue: 0, missedRevenue: 0, upliftPotential: 0, averageUnitsSold: 0, averageSKU: 0, uniqueAssetsCount: 0 });
    const clientsList = useSelector(state => state.appReducer.clientsList) || {};
    const dispatch = useDispatch();
    const { t: trans, i18n } = useTranslation();
    const tOpts = { t: trans, i18n };
    const t = utils.t;

    React.useEffect(() => {
        let gridFilter = [];
        const selectedDate = dayjs(dateValue[0]).format('YYYY-MM-DD');
        const currentDate = dayjs(dateValue[1]).format('YYYY-MM-DD');
        const differenceInDays = dayjs(currentDate).diff(dayjs(selectedDate), 'day') + 1;
        setDateDiff(differenceInDays);
        if (dateValue) {
            gridFilter = [{ value: selectedDate, field: "PurityDateOnly", pivotFilterField: "StartDate", operator: ">=" }, { value: currentDate, field: "PurityDateOnly", pivotFilterField: "EndDate", operator: "<=" }];
        }
        if (gridFilter) {
            setGridFilters(gridFilter);
        }
    }, []);


    React.useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading='Missed Opportunity' titleDescription='Missed Opportunity is the value of products being unavailable in stores, achieve this with a calculation on the sales rate of each product on each store and the projection of sales based on time the product were unavailable.' title='Missed Opportunity' /> });
    }, [])

    const handleClientSelectChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === -1) {
            setSelectedClients(selectedClients.length === clientsList.length ? [] : clientsList?.map(client => client.Key));
            return;
        }
        setSelectedClients(value);
    };

    function onResetClick() {
        setSelectedClients([Number(ClientId)]);
        setDateValue([dayjs().subtract(30, 'day'), dayjs()]);
    }

    function onApplyFilterClick() {
        if (dayjs(dateValue[0]).isValid() && dayjs(dateValue[1]).isValid()) {
            const selectedDate = dayjs(dateValue[0]).format('YYYY-MM-DD');
            const currentDate = dayjs(dateValue[1]).format('YYYY-MM-DD');
            const differenceInDays = dayjs(currentDate).diff(dayjs(selectedDate), 'day') + 1;
            setDateDiff(differenceInDays);
            let gridFilter = [];
            if (dateValue) {
                gridFilter = [{ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "PurityDateOnly", operator: ">=", pivotFilterField: 'StartDate' }, { value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "EndDate", operator: "<=" }];
            }
            if (gridFilter) {
                setGridFilters(gridFilter);
            }
            setActiveClients(selectedClients);
            if (selectedClients.join(",") === activeClients.join(",")) {
                postDataFunction();
            }
        }
        else {
            swal({ text: "Invalid Date, please pass date in correct format", icon: "error" });
        }
    }


    useEffect(() => {
        postDataFunction();
    }, [activeClients]);



    const gridPivotFilter = React.useMemo(() => {
        const grFilter = [];
        if (selectedClients?.length) {
            grFilter.push({ value: selectedClients, field: "selectedClients" });
        }
        if (dateValue?.length) {
            grFilter.push({ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "StartDate" });
            grFilter.push({ value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "EndDate" });
        }
        return grFilter;
    });

    async function postDataFunction() {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        const headers = {};
        let params = {
            url: `${apis.MissedOpportunityCards}`,
            method: 'POST',
            data: { ClientId, selectedClients: activeClients, dateValue: { startDate: dayjs(dateValue[0]).format('YYYY-MM-DD'), endDate: dayjs(dateValue[1]).format('YYYY-MM-DD') } },
            headers: {
                "Content-Type": "application/json",
                ...headers
            },
            credentials: 'include'
        };
        const response = await transport(params);
        if (response?.data) {
            setCardValues(response.data);
        }
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginTop: 15, marginBottom: 10 }}>
                            {t("Missed Opportunity", tOpts)} {dateDiff ? `- ${t("Last", tOpts)} ${dateDiff} ${t("Days", tOpts)}` : null}
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginBottom: 10 }}>
                            {t("Filters", tOpts)}
                        </div>
                        <div style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <div>
                                    <DateRangePicker value={dateValue} onChange={(newValue) => setDateValue(newValue)} />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ marginLeft: 15 }} className="missed-opportunity-client-select">
                                    <MultiSelectDropdown config={config} options={clientsList} selectedOptions={selectedClients} handleChange={handleClientSelectChange} addMargin={false} width={220} subStringlength={45} />
                                </div>
                            </Grid>
                        </div>
                        <div style={{ marginTop: 10, display: 'flow-root' }}>
                            <Button variant="text" style={{ float: 'left' }} onClick={() => onResetClick()}>{t("Reset", tOpts)}</Button>
                            <Button variant="text" style={{ float: 'right' }} onClick={() => onApplyFilterClick()}>{t("Apply", tOpts)} {t("Filter", tOpts)}</Button>
                        </div>
                    </Item>
                </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", gap: "10px" }}>
                {cardsToRender.map((ele) => {
                    const { key, title, value, addCurrencySymbol = false, addPercentageSymbol = false } = ele;
                    const cardValue = value in cardValues ? Math.round(cardValues[value]) : 0;
                    return (
                        <Paper elevation={3} key={key} sx={{
                            p: 2, mt: 3, mb: 1, display: "flex", justifyContent: "center", flexGrow: 1,
                            flexBasis: 'calc(33.3% - 10px)',
                            maxWidth: "300px",
                            minHeight: "200px"
                        }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", minHeight: 110, height: 80, marginTop: 10, fontSize: 'medium', fontWeight: 500 }}>{t(title, tOpts)}</div>
                                <div style={{ display: "flex", justifyContent: "center", fontSize: 'x-large', fontWeight: 500 }}>{addCurrencySymbol ? `${CurrencySymbol} ` : ''}{cardValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}{addPercentageSymbol ? " %" : ""}</div>
                            </div>
                        </Paper>
                    )
                })}
            </div>
            <Grid sx={{ marginTop: 2 }}>
                <missedOpportunityOutletModel.Grid additionalFilters={gridFilters} reRenderKey={gridFilters} selectedClients={activeClients} gridPivotFilter={gridPivotFilter} />
            </Grid>
        </Box>
    );
})

export default HorizontalBars;