import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Card, CardContent, CardActions, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro';
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { apis, request } from "../../httpUtil";
import { ORDER_STATUS, STRINGS_CONST } from "../../constants";
import utils from '../../utils';
import enums from '../../utils/constants';
import actions from "./../../redux/actions";
import ExportExcelFile from './../ExportFile/ExportExcelFile';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import useMobile from '../../utils/useMobile';
import { useDebounce } from 'use-debounce';
import { useParams } from "react-router-dom"
import { useSnackbar } from "@durlabh/dframework-ui";
import { ExpandedRow, GridBase } from "./../DeliveriesList/LocationReorderExpand";
import InboxOrderAnalysis from "./InboxOrderAnalysis";
import constants from "../../utils/constants";
import DialogModal from "../Common/DialogModel";

const { isModuleExists } = utils;
const { turkeyOrderValidateModule, turkeyOrderValidateKey, inboxAndSalesChartsModule } = enums;
let timer = null;
const defaultFilter = { items: [], linkOperator: "and" };

const Inbox = (props) => {
    const isMobileDevice = useMobile();
    const gridRef = useGridApiRef();
    const customExportRef = useRef();

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const { columns, isReorder, globalFilter, refresh, onClickItem, multiClientFilters } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const gridData = useSelector(state => state.appReducer.replenishmentGridData);
    const overviewRecordSelect = useSelector(state => state.appReducer.overviewRecordSelect);
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientId, CurrencySymbol, CustomDateFormat: DateFormat } = userData?.tags ? userData.tags : 0;
    const { data, editProductRowId = 0 } = gridData;
    const { locationSearch } = useSelector(state => state.appReducer);
    const [searchDebounce] = useDebounce(locationSearch, 1000)
    const locationParams = useParams();
    const { locationId } = locationParams;
    const { DeliveryNotesCaption } = userData?.tags || {};
    const snackbar = useSnackbar();

    const columnsVisibilityModel = {
        Code: true,
        LocationName: true,
        Street: !isMobileDevice.mobile,
        City: !isMobileDevice.mobile,
        State: !isMobileDevice.mobile,
        PostalCode: false,
        OrderSuggestion: !isMobileDevice.mobile,
        LastImageDateTime: false,
        DeliveryNotes: false,
        ShareOfShelf: false,
        TotalCase: !isMobileDevice.mobile,
        FinalTotalCase: !isMobileDevice.mobile,
        TotalOrder: !isMobileDevice.mobile,
        MarketName: false,
        LastDeliveryDate: !isMobileDevice.mobile,
        LastOrderDate: !isMobileDevice.mobile,
        LocationTypeName: false,
        ClassificationName: false,
        LocationRoute: false,
        PreSellerRouteName: false,
        SalesRepName: false,
        Image: false,
        FinalMoQ: false,
        FinalMoV: false,
        __detail_panel_toggle__: !isMobileDevice.mobile,
        LastMOVAndMOQDateTime: false,
        ClientName: false
    }

    let initialGridColumns;

    if (!initialGridColumns) {
        let inboxGridColumns = columns({ action: onClickItem, t, tOpts, isMobileDevice, caseKey: "FinalTotalCase", valueKey: "FinalTotalOrder", DateFormat, DeliveryNotesCaption, LastDeliveryDate: true, onOutletCodeCellClick: onClickItem });
        initialGridColumns = inboxGridColumns.filter(ele => ele.field !== 'LastImageDateTime');
    }

    // States
    const [params, setParams] = useState({ ...utils.listDefaultParams, locationStatusId: 5547, IncludeKPI: true, sort: 'FinalTotalOrder', dir: 'desc' });
    const [oldParams, setOldParams] = useState({});
    const [manualRefresh, setManualRefresh] = useState(0);
    const [headerBtnEnable, setHeaderBtnEnable] = useState(true);
    const [checkedData, setCheckedData] = useState([]);
    const [expandedRowId, setExpandedRowId] = useState('');
    const [filterModel, setFilterModel] = useState(defaultFilter);
    const [sortModel, setSortModel] = useState();
    const [gridColumns, setGridColumns] = useState();
    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [columnVisibility, setColumnVisibility] = useState(columnsVisibilityModel)
    const [isGridPreferenceFetched, setIsGridPreferenceFetched] = useState(false);
    const [fetchData, setFetchData] = useState(false);
    const [open, setOpen] = useState(false);
    const [IssueType, setIssueType] = useState('');
    const [issueDetails, setIssueDetails] = useState('');
    const [currentRow, setCurrentRow] = useState(null);
    const [currentTab, setCurrentTab] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleOpen = ({ row, tab }) => {
        setCurrentRow(row);
        setCurrentTab(tab);
        setOpen(true);
    };

    const handleClose = () => {
        setIssueType('');
        setIssueDetails('');
        setOpen(false);
        setIsDisabled(true);
    };

    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        disableAggregation: true,
        disableRowGrouping: true,
        filterModel: filterModel,
        sortModel: sortModel,
        paginationModel: paginationModel,
        setPaginationModel: (e) => {
            setPaginationModel(e);
            clearExpandedRow();
        },
        paginationMode: 'server',
        page: params.start || 0,
        pageSize: params.limit,
        onPageChange: (pageVal) => {
            cancelOverviewRecordSelect();
            setParams((prev) => ({ ...prev, start: pageVal }));
            clearExpandedRow();
        },
        onPageSizeChange: (pageSizeVal) => {
            cancelOverviewRecordSelect();
            setParams((prev) => ({ ...prev, start: 0, limit: pageSizeVal }));
            clearExpandedRow();
        },
        onFilterModelChange: (filterValues) => {
			filterValues.items.forEach(x => {
				const filterColumn = gridColumns?.find(y=>y.field === x.field);
				x.type = filterColumn.type
			});
            cancelOverviewRecordSelect();
            setFilterModel(filterValues);
            clearExpandedRow();
        },
        onSortModelChange: (sortVal) => {
            if (sortVal.length > 0) {
                const { field, sort } = sortVal[0];
                params["sort"] = field;
                params["dir"] = sort;
            } else {
                delete params["sort"];
                delete params["dir"];
            }
            setParams((prev) => ({ ...prev, ...params }));
            clearExpandedRow();
            setSortModel(sortVal);
        },
        disableSelectionOnClick: !(isMobileDevice.mobile && !isMobileDevice.tablet),
        density: "compact",
        hideFooter: false,
    }

    const cancelOverviewRecordSelect = () => {
        if (overviewRecordSelect.code) {
            dispatch({ type: actions.OVERVIEW_RECORD_SELECT, overviewRecordSelect: { code: null } });
        }
    }

    useEffect(() => {
        dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick } });
        return () => {
            dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick: null } });
        }
    }, [globalFilter, params, data]);

    useEffect(() => {
        if (refresh > 0 && isGridPreferenceFetched)
            getData({ manualRefresh: false });
    }, [params, filterModel, refresh, paginationModel, isGridPreferenceFetched]);

    useEffect(() => {
        if (manualRefresh > 0 && isGridPreferenceFetched)
            getData({ manualRefresh: true });
    }, [manualRefresh, isGridPreferenceFetched]);

    useEffect(() => {
        if (searchDebounce !== undefined) {
            setParams({ ...params, assetOrLocationName: searchDebounce })
        }
    }, [searchDebounce]);

    useEffect(() => {
        clearTimeout(timer);
        const { records } = gridData.data;
        if (records && records.length > 0 && editProductRowId !== 0) {
            timer = setTimeout(() => utils.autoExpendRow(gridRef, editProductRowId, setExpandedRowId), 500);
        }
    }, [gridData]);

    function clearExpandedRow() {
        setExpandedRowId();
    }

    const onScheduledIgnoreClick = (e, orderStatus, row) => {
        const record = row ? [row.LocationId] : checkedData;
        const successCBK = () => setManualRefresh((val) => (val + 1));
        utils.onScheduledIgnoreClick({ e, isMulti: true, data, statusId: orderStatus, isReorder, dispatch, history, checkedData: record, t, successCBK, addNotes: isModuleExists(userData, turkeyOrderValidateModule), tOpts, clearSelectionModel, clearExpandedRow });
    }

    async function getData({ manualRefresh }) {
        let payload = utils.deepCloneObject(params);
        payload.limit = paginationModel.pageSize;
        payload.start = paginationModel.page;
        payload.start = payload.start * payload.limit;
        payload = utils.updateFilter(payload, filterModel, globalFilter, overviewRecordSelect, false);
        utils.applyMultiClientFilters(payload, globalFilter, multiClientFilters);
        if (Number(locationId) > 0) {
            payload.LocationId = Number(locationId);
        }

        let payloadChanged = JSON.stringify(payload) === JSON.stringify(oldParams)
        if(Object.keys(payload.filter).length === 0) {
            payloadChanged = false;
        }
        if (!payloadChanged || manualRefresh) {
            setOldParams(payload);
            let finalPayload = utils.deepCloneObject(payload);

            if (Object.keys(payload.filter).length === 0) {
                delete finalPayload.filter;
            }
            const caseValidation = utils.validateRange('casesRange', 'Cases', finalPayload, t, tOpts, snackbar);
            const orderValidation = utils.validateRange('orderRange', 'Order', finalPayload, t, tOpts, snackbar);
            if (!caseValidation || !orderValidation) {
                return;
            }           
        
            const data = await request({ url: apis.ReorderDetailLite, params: finalPayload, history, dispatch });
            if (data) {
                dispatch({ type: actions.REPLENISHMENT_GRID_DATA, replenishmentGridData: { data: data } });
                if (!fetchData) {
                    setFetchData(true);
                }
            }
        }
    }

    function clearFilter() {
        dispatch({ type: actions.OVERVIEW_RECORD_SELECT, overviewRecordSelect: { code: null } });
        setParams((prev) => ({ ...prev, start: 0, filter: [], filterValue: [] }));
        setFilterModel({
            pagination: { page: 0 },
            linkOperator: "and",
            items: []
        })
    }

    const onColumnVisibilityModelChange = (columns) => {
        setColumnVisibility(columns);
    }

    function onSelectionModelChange(selectionModel, details) {
        setHeaderBtnEnable(selectionModel.length === 0);
        const selectedRowData = [];
        selectionModel.forEach(key => {
            selectedRowData.push(details?.current?.state?.rows?.dataRowIdToModelLookup[key]?.LocationId);
        });
        setCheckedData(selectedRowData);
    }

    function clearSelectionModel() {
        setCheckedData([]);
    }

    const onExportClick = () => {
        if (data?.records?.length === 0 || data.length === 0) {
            swal({
                title: '',
                text: "No data to export",
                icon: "warning",
                dangerMode: false
            });
            return;
        }
        const { orderedFields, columnVisibilityModel, lookup } = gridRef.current.state.columns;
        const hiddenColumns = Object.keys(columnVisibilityModel).filter(key => columnVisibilityModel[key] === false);
        const visibleColumns = orderedFields.filter(ele => !hiddenColumns?.includes(ele) && ele !== '__check__' && ele !== '__detail_panel_toggle__' && ele !== 'Image');
        if (visibleColumns?.length === 0) {
            snackbar.showMessage(t('You cannot export while all columns are hidden... please show at least 1 column before exporting', tOpts));
            return;
        }
        const gridExportColumns = [];
        visibleColumns.forEach(ele => {
            const { type, headerName } = lookup[ele];
            gridExportColumns.push({ id: ele, label: headerName, type: type });
        })

        const _cols = gridExportColumns.map((e) => {
            const { label, type } = e;
            let { id } = e;
            // if (id === enums.locationReorderExportFields.OrderSuggestion) {
            //     id = enums.locationReorderExportFields.LastImageDate;
            // }
            // if (id === enums.locationReorderExportFields.LastDeliveryDate) {
            //     id = enums.locationReorderExportFields.LastDeliveryDateString;
            // }
            // if (id === enums.locationReorderExportFields.LastOrderDate) {
            //     id = enums.locationReorderExportFields.LastOrderDateString;
            // }
            return {
                dataIndex: id,
                title: label,
                type: type
            }
        })
        let _params = utils.deepCloneObject(params);
        _params = utils.updateFilter(_params, filterModel, globalFilter, overviewRecordSelect);
        utils.applyMultiClientFilters(_params, globalFilter, multiClientFilters);
        if (Number(ClientId) > 0) {
            _params = { ..._params, ...{ clientId: Number(ClientId) } }
        }
        if (Object.keys(_params.filter).length === 0) {
            delete _params.filter;
        }
        utils.fileExport({ form: customExportRef.current, params: _params, columns: _cols, currencySymbol: CurrencySymbol, title: STRINGS_CONST.REPLENISHMENT_ORDER_STATUS_INBOX });

    }

    const validateOrder = async ({ data }) => {
        const products = [], productsSKU = [];
        let indexValue = -1;

        for (const product of data.ProductInfo) {
            indexValue = products.findLastIndex(obj => obj.ShortEAN === product.SKU);
            if (indexValue > -1) {
                products[indexValue].Quantity += product.UnitsNeeded;
            } else {
                let unitLabel = product && product.UnitLabelId === enums.UnpackUnitLabelId ? product.UnitLabel : enums.UnitLabel.packLabel;
                products.push({ "ShortEAN": product.SKU, "Quantity": product.UnitsNeeded, "Unit": unitLabel });
                productsSKU.push(product.SKU);
            }
        }
        let payload = {
            "order": [{
                "ExternalOrderNo": data.LocationId,
                "CustomerNo": data.Code,
                "CreatedAt": data.LastImageDateTime,
                "OrderLine": products
            }]
        }

        let response = await request({
            url: apis.TurkeyOrderValidate, params: payload, jsonPayload: true, history, dispatch, additionalHeaders: {
                'x-api-key': turkeyOrderValidateKey
            }
        });

        if (response?.order?.length) {
            await updateOrders(response.order, productsSKU);
        } else {
            swal({
                title: t("Validation Error", tOpts),
                icon: "error",
                dangerMode: true
            })
        }
    }

    const updateOrders = async (orders, productsSKU) => {
        let singleRecord = orders.length === 1;
        const gridDataClone = utils.deepCloneObject(gridData);

        for (const order of orders) {
            const index = gridDataClone.data.records.findIndex(e => e.LocationId === order.externalOrderNo);
            if (index === -1) {
                continue
            }
            if (singleRecord) {
                singleRecord = index
                if (order.error) {
                    let noteString = order.orderNotes;
                    noteString = noteString.split('\r\n');
                    return swal({
                        title: t("Validation Error", tOpts),
                        text: noteString.join("\n"),
                        icon: "error",
                        dangerMode: true
                    })
                }
            }
            // Remove duplicate Products
            const obj = [];
            let indexValue = -1;
            gridDataClone.data.records[index].ProductInfo.forEach((value) => {
                indexValue = obj.findIndex((e) => e.SKU === value.SKU);
                if (indexValue > -1) {
                    obj[indexValue].UnitsNeeded += value.UnitsNeeded;
                    obj[indexValue].UnitPrice += value.UnitPrice;
                } else {
                    obj.push(value);
                }
            });
            gridDataClone.data.records[index].ProductInfo = obj;

            const productsList = await loadProduct(), newProductsMap = [], newProducts = [];
            for (const product of order.orderLine) {
                for (let i = 0; i < gridDataClone.data.records[index].ProductInfo.length; i++) {
                    let newQuantity = Number(product.quantity);
                    newQuantity = newQuantity === 0 ? '0' : newQuantity;
                    if (gridDataClone.data.records[index].ProductInfo[i].SKU === product.shortEAN) {
                        if (newQuantity === '0') {
                            gridDataClone.data.records[index].ProductInfo[i].bgColor = "rgba(255, 99, 132, 0.7)";
                        }
                        gridDataClone.data.records[index].ProductInfo[i].UnitsNeeded = newQuantity;
                        gridDataClone.data.records[index].ProductInfo[i].UnitPrice = product.unitPrice;
                        gridDataClone.data.records[index].ProductInfo[i].Amount = Number(newQuantity) * Number(product.unitPrice);
                    }
                    else {
                        if (!newProductsMap.includes(product.shortEAN) && !productsSKU.includes(product.shortEAN)) {
                            newProductsMap.push(product.shortEAN);
                            let newProduct = productsList.filter((item) => item.SKU === product.shortEAN);
                            newProduct = newProduct[0];
                            if (newProduct) {
                                let caseSize = await loadProductCaseType(newProduct.LookupId);
                                caseSize = caseSize[0];
                                newProducts.push({
                                    "Id": 0,
                                    "UnitsNeeded": newQuantity,
                                    "UnitPrice": product?.unitPrice,
                                    "StockLevel": 0,
                                    "ProductId": newProduct?.LookupId,
                                    "CaseSize": caseSize?.CaseSize,
                                    "Amount": Number(newQuantity) * Number(product?.unitPrice),
                                    "ProductName": newProduct?.DisplayValue,
                                    "SKU": newProduct?.SKU,
                                    "customId": utils.generateUUID(),
                                    "bgColor": "rgba(75, 192, 192, 0.7)"
                                });
                            }
                        }
                    }
                }
            }

            if (newProducts.length) {
                gridDataClone.data.records[index].ProductInfo = [...gridDataClone.data.records[index].ProductInfo, ...newProducts];
            }

            let newCases = 0, newValue = 0;
            for (const product of gridDataClone.data.records[index].ProductInfo) {
                newCases += Number(product.UnitsNeeded);
                newValue += (Number(product.UnitPrice) * Number(product.UnitsNeeded));
            }
            gridDataClone.data.records[index].TotalCaseSize = newCases;
            gridDataClone.data.records[index].TotalCase = newCases;
            gridDataClone.data.records[index].FinalTotalCase = newCases;
            gridDataClone.data.records[index].OrderSize = Math.round(newValue);
            gridDataClone.data.records[index].FinalTotalOrder = Math.round(newValue);
            gridDataClone.data.records[index].TotalOrder = Math.round(newValue);
            gridDataClone.data.records[index].Notes = order.orderNotes;
            gridDataClone.data.records[index].Amount = Math.round(newValue);
        }
        if (singleRecord !== false) {
            let noteString = gridDataClone.data.records[singleRecord].Notes;
            noteString = noteString.split('\r\n');
            swal({
                title: t("Validation Done", tOpts),
                text: noteString.join("\n"),
                dangerMode: false
            })
        }
        dispatch({ type: actions.REPLENISHMENT_GRID_DATA, replenishmentGridData: { data: gridDataClone.data, editProductRowId: singleRecord !== false ? gridDataClone.data.records[singleRecord].RowNumber : null } });
    }

    const loadProduct = async () => {
        const params = { start: 0, comboType: 'Product', asArray: 0, limit: 0, ClientId: ClientId, OnlyClientProducts: true, LoadIsActiveProducts: true }
        const response = await request({ url: apis.Combo, params, history, dispatch });
        if (response?.records) {
            return Object.keys(response.records).map((key) => response.records[key])
        }
    }

    const loadProductCaseType = async (productId) => {
        const params = { start: 0, limit: 0, comboType: "ProductPrice", asArray: 0, ProductId: productId }
        let response = await request({ url: apis.Combo, params, history, dispatch });
        if (response?.records) {
            response = response.records
        } else {
            response = {}
        }
        return response;
    }

    function expandedRowIdsChange(row) {
        let id = '';
        if (Array.isArray(row)) {
            id = row[1];
        } else {
            id = row.id;
        }
        if (expandedRowId === id) {
            setExpandedRowId("")
        } else {
            setExpandedRowId(id)
        }
    }

    const onPinnedColumnsChange = (pinnedColumns, details) => {
        const pinCols = pinnedColumns;
        const index = pinCols.right.indexOf(GRID_DETAIL_PANEL_TOGGLE_FIELD);
        pinCols.right.splice(index, 1);
        pinCols.right.push(GRID_DETAIL_PANEL_TOGGLE_FIELD);
        gridRef.current.state.pinnedColumns = pinCols;
    }

    function closeExpandedRows() {
        setExpandedRowId('');
    }

    const getDetailPanelContent = ({ row, id }) => {
        return (
            <Card>
                <CardContent sx={{ padding: '8px' }}>
                    <ExpandedRow data={row.ProductInfo} isReorder={false} locationId={row.LocationId} closeExpandedRows={closeExpandedRows} onClickItem={onClickItem} row={row} filterValues={globalFilter} multiClientFilters={multiClientFilters} />
                </CardContent>
                <CardActions>
                    <Grid justifyContent="flex-end" container spacing={1}>
                        <Grid item >
                            <Button size="small" variant="outlined" color="secondary" className="background-theme-brown sub-header-button location-reorder-yellow-help" onClick={(e) => handleOpen({ row, tab: constants.Inbox })} >{t("Help", tOpts)}</Button>
                        </Grid>
                        <Grid item >
                            <Button size="small" variant="contained" color='warning' className="background-theme-red sub-header-button Location-Reorder-red" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.IGNORED, row)}>{t("Ignore", tOpts)}</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" variant="contained" color="secondary" className="background-theme-brown sub-header-button Location-Reorder-yellow" onClick={(e) => dispatch({ type: actions.EDIT_REPLENISHMENT_ORDER, replenishmentOrder: { open: true, data: row } })} >{t("Edit", tOpts)}</Button>
                        </Grid>
                        {isModuleExists(userData, turkeyOrderValidateModule) && <Grid item>
                            <Button size="small" variant="contained" color="secondary" className="background-theme-blue sub-header-button Location-Reorder-blue" onClick={(e) => validateOrder({ data: row })} >{t("Validate", tOpts)}</Button>
                        </Grid>}
                        <Grid item >
                            <Button size="small" variant="contained" color='primary' className="background-theme-green sub-header-button Location-Reorder-green" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.SCHEDULED, row)}>{t("Schedule", tOpts)}</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        )
    }

    if (isMobileDevice.mobile && !isMobileDevice.tablet) {
        commonGridOptions.selectionModel = [expandedRowId]
    } else {
        delete commonGridOptions.selectionModel;
    }

    if (!gridColumns) {
        let inboxGridColumns = columns({ action: onClickItem, t, tOpts, isMobileDevice, caseKey: "FinalTotalCase", valueKey: "FinalTotalOrder", DateFormat, DeliveryNotesCaption, LastDeliveryDate: true, onOutletCodeCellClick: onClickItem });
        inboxGridColumns = inboxGridColumns.filter(ele => ele.field !== 'LastImageDateTime');
        setGridColumns(inboxGridColumns);
    }

    return (
        <>
            {(!isMobileDevice.mobile && isModuleExists(userData, inboxAndSalesChartsModule)) &&
                <InboxOrderAnalysis refresh={refresh} filterValues={globalFilter} valueField='FinalTotalOrder' caseField='FinalTotalCase' source='Stock' keyColumn='LocationId' fetchData={fetchData} tabsData={utils.chartsTabsEnum} additionalWhere={{ OrderStatusId: { value: "0", fieldName: "OrderStatusId", operator: "=" }, IsActive: { value: "1", fieldName: "IsActive", operator: "=" } }} />
            }
            {isMobileDevice.mobile && !isMobileDevice.tablet ? "" :
                <Grid justifyContent="flex-end" container spacing={1}>
                    <Grid item >
                        <Button size="small" disabled={headerBtnEnable} variant="contained" className="background-theme-red sub-header-button Location-Reorder-red" color='primary' onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.IGNORED)}>{t("Ignore", tOpts)}</Button>
                    </Grid>
                    <Grid item>
                        <Button size="small" disabled={headerBtnEnable} variant="contained" color="secondary" className="background-theme-green sub-header-button Location-Reorder-green" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.SCHEDULED)}>{t("Schedule", tOpts)}</Button>
                    </Grid>

                </Grid>}
            <GridBase
                key={`inbox-grid-${JSON.stringify(isMobileDevice)}`}
                clearFilter={clearFilter}
                customClass={`mt-2 data-grid-font-12 location-reorder-grid-overrides`}
                columns={gridColumns}
                data={data.records}
                rowsPerPage={[10, 20, 50]}
                movePagination={false}
                showTitleColumn={!(isMobileDevice.mobile && !isMobileDevice.tablet)}
                onRowDoubleClick={onClickItem}
                gridRef={gridRef}
                columnVisibilityModel={columnVisibility}
                preferenceName='locationReorderInbox'
                setIsGridPreferenceFetched={setIsGridPreferenceFetched}
                otherOptions={{
                    rowCount: data?.recordCount,
                    checkboxSelection: !(isMobileDevice.mobile && !isMobileDevice.tablet),
                    getDetailPanelContent: getDetailPanelContent,
                    onSelectionModelChange: onSelectionModelChange,
                    getRowId: (row) => row.RowNumber,
                    onRowClick: (row) => isMobileDevice.mobile ? expandedRowIdsChange(row) : null,
                    detailPanelExpandedRowIds: [expandedRowId],
                    onDetailPanelExpandedRowIdsChange: expandedRowIdsChange,
                    disableColumnMenu: !!(isMobileDevice.mobile && !isMobileDevice.tablet),
                    onColumnVisibilityModelChange: onColumnVisibilityModelChange,
                    selectionModel: checkedData,
                    onSortModelChange: commonGridOptions.onSortModelChange,
                    onFilterModelChange: commonGridOptions.onFilterModelChange,
                    // pinnedColumns: { right: [GRID_DETAIL_PANEL_TOGGLE_FIELD] },
                    onPinnedColumnsChange: onPinnedColumnsChange,
                    ...commonGridOptions
                }}
            />
            <ExportExcelFile ref={customExportRef} url={apis.ReorderDetailLite} />
            <DialogModal
                open={open}
                onClose={handleClose}
                onSubmit={(e) => {
                    e.preventDefault();
                    utils.handleSubmit(e, { currentRow, currentTab, IssueType, issueDetails: utils.orderIssueOption, userData, snackbar, t, tOpts, handleClose, setIssueDetails, setIssueType, dispatch });
                }}
                dialogTitle="Please select Issue Type"
                submitBtnTitle="Submit"
                closeBtnTitle="Cancel"
                maxWidth="sm"
                maxHeight='sm'
                closeBtnCls="light"
                submitBtnColor="success"
                actionButtonsCls='dialog-actions-centered '
                className={IssueType === 'Others' ? 'vista-dialog-bx' : ''}
                normalHeight={false}
                submitBtnDisabled={(!IssueType)}
            >
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <RadioGroup
                            aria-label="issue-type"
                            name="IssueType"
                            value={IssueType}
                            onChange={(e) => utils.handleIssueTypeChange(e, { setIssueType })}
                        >
                            <FormControlLabel value="OverOrder" control={<Radio />} label={t("Over Order", tOpts)}  />
                            <FormControlLabel value="UnderOrder" control={<Radio />} label={t("Under Order", tOpts)}  />
                            <FormControlLabel value="IncorrectOrder" control={<Radio />} label={t("Incorrect Order", tOpts)}  />
                            <FormControlLabel value="Others" control={<Radio />} label={t("Others", tOpts)}  />
                        </RadioGroup>
                    </Grid>
                    {IssueType === 'Others' && (
                        <Grid item>
                            <TextField
                                label={t("Comments", tOpts)} 
                                variant="outlined"
                                margin="dense"
                                name="issueType"
                                value={utils.orderIssueOption}
                                onChange={(e) => utils.handleIssueTypeChange(e, { snackbar, optionType: 'others' })}
                                fullWidth
                                multiline
                                rows={5}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogModal >
        </>
    )
}
export default Inbox;