import * as React from 'react';
import {
    ResponsiveChartContainer,
    ChartsYAxis,
    ChartsXAxis,
    ChartsTooltip,
    BarPlot
} from '@mui/x-charts';
import { Paper, Box } from '@mui/material';

export default function XHorizontalStackedChart({ data, categories, xLabel, margin }) {
    if (!data) return null;
    const series = data.map((entry) => ({
        type: 'bar',
        stack: 'background',
        layout: 'horizontal',
        data: entry?.data || [],
        label: entry?.name || '',
        color: entry?.color.length > 0 && entry?.color.map(item => item !== undefined ? item : '') || '#000',
    }));
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', height: 500 }} elevation={3}>
                <ResponsiveChartContainer
                    yAxis={[
                        {
                            scaleType: 'band',
                            data: categories,
                        },
                    ]}
                    series={series}
                    skipAnimation
                    grid={{ vertical: true }}
                    margin={margin}
                >
                    <BarPlot />
                    <ChartsYAxis />
                    <ChartsXAxis label={xLabel} />
                    <ChartsTooltip trigger='item' />
                </ResponsiveChartContainer>
            </Paper>
        </Box>
    );
}
