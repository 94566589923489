import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apis } from '../../httpUtil';
import useMobile from '../../utils/useMobile';

function ClientLogo({ customClassName = "" }) {
  const isMobileDevice = useMobile();
  const userData = useSelector(state => state.appReducer.userData);
  const [clientLogo, setClientLogo] = useState({ exist: false, url: "" });

  useEffect(() => {
    if (!userData) return;

    const tags = userData.tags ?? {};
    const clientId = Number(tags.ClientId) || "";
    const isFromDemoSite = !!tags.IsFromDemo;
    const ClientName = tags.ClientName || "";
    const IsClientImageExists = !!tags.IsClientImageExists;
  
    const clientLogoURL = isFromDemoSite
      ? `${apis.ClientLogoBaseUrl}?ImageName=demosite.png&ImageType=ClientLogo`
      : clientId
      ? `${apis.ClientLogoBaseUrl}?ImageName=${clientId}.png&ImageType=ClientLogo`
      : "";
  
    setClientLogo({
      exist: IsClientImageExists,
      url: IsClientImageExists ? clientLogoURL : ClientName,
    });
  }, [userData]);
  

  return <>
    {clientLogo.exist ?
      <img src={clientLogo.url} width="80" alt="client logo" /> :
      isMobileDevice.mobile && !isMobileDevice.tablet ? '' : <span className={`client-logo-name ${customClassName}`}>
        {clientLogo.url}
      </span>}
  </>
}

export default ClientLogo;
