import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import actions from "../../../redux/actions";
import PageTitle from "../../PageTitle";
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import utils from "../../../utils";
import constants from "../../../utils/constants";
import { apis, request } from "../../../httpUtil";
import { makeStyles } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, FiberManualRecord } from "@material-ui/icons";
import operationalModel from "../../../pages/OperationalDashboardConfig";
import MultiLineChart from "../../SurveyAnalysis/MultiLineChart";
import dayjs from "dayjs";
import { useSnackbar } from "@durlabh/dframework-ui";
import SurveyMap from "./SurveyMap";
import { KeyboardDatePicker } from "@material-ui/pickers";
import XBarChart from "../../SurveyAnalysis/XBarChart";
import XHorizontalStackedChart from "./XHorizontalStackedChart";

const { t } = utils;
const dateFilterMaxDate = new Date();
const { defaultCoverageOption, defaultSubmissionsOption, defaultSubmissionsOrder } = constants;

const useStyles = makeStyles((theme) => ({
    kpiContainer: {
        marginTop: '10px !important',
        marginBottom: '20px !important'
    },
    surveyFilter: {
        width: '100% !important'
    },
    submissionsPerContainer: {
        width: '100% !important',
        height: '100% !important'
    },
    coverageContainer: {
        width: '100% !important'
    },
    kpiCardContainer: {
        width: '250px !important'
    },
    kpiCard: {
        width: '100% !important'
    },
    surveySelectContainer: {
        position: 'relative',
    },
    surveyApplyBtn: {
        width: '100% !important',
        marginTop: 'auto',
        position: 'sticky',
        bottom: 0
    },
    mapContainer: {
        marginTop: '10px !important',
        marginBottom: '10px !important',
        width: '100% !important'
    },
    stickyButtonContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        position: 'sticky',
        bottom: 0,
        padding: theme.spacing(1)
    },
    customPaper: {
        padding: theme.spacing(3)
    },
    chartPaper: {
        marginTop: theme.spacing(3)
    },
    submissionsPaper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        height: '100%'
    }

}));

function SurveyOperationalDashboard() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const comboData = useSelector(state => state.appReducer.comboData) || {};
    const reportingCombos = useSelector(state => state.appReducer.resultAnalysisComboData) || {};
    const { Market, Distributor } = reportingCombos;
    const { LocationType: Channels, LocationClassification: Classification } = comboData;
    const filterValues = useSelector(state => state.appReducer.filterValues);
    const [allSurveys, setAllSurveys] = useState([]);
    const [selectedAllSurveys, setSelectedAllSurveys] = useState([]);
    const [surveyOutlets, setSurveyOutlets] = useState([]);
    const defaultFromDate = dayjs().subtract(30, 'day').format(constants.dateFormat.yymmdd);
    const defaultToDate = dayjs(dateFilterMaxDate).format(constants.dateFormat.yymmdd);
    const [tempFilterModel, setTempFilterModel] = useState({});
    const startDateFilterModel = {
        field: 'SurveyDateTime',
        value: defaultFromDate,
        operator: 'onOrAfter',
        type: 'date'
    };
    const endDateFilterModel = {
        field: 'SurveyDateTime',
        value: defaultToDate,
        operator: 'onOrBefore',
        type: 'date'
    };
    const [isFilter, setIsFilter] = useState(false);
    const [submissionsPerOption, setSubmissionsPerOption] = useState(defaultSubmissionsOption);
    const [orderSubmissionsPer, setOrderSubmissionsPer] = useState(defaultSubmissionsOrder)
    const [coveragePerOption, setCoveragePerOption] = useState(defaultCoverageOption)
    const [submissionsPer, setSubmissionsPerData] = useState([]);
    const [surveyCoverageData, setSurveyCoverageData] = useState([]);
    const [surveySubmissionsInTimeData, setSurveySubmissionsInTimeData] = useState([]);
    const format = utils.systemDateTimeFormat(true);
    const [allSurveyFilter, setAllSurveyFilter] = useState(true);
    const snackbar = useSnackbar();
    const [loadingSubmissions, setLoadingSubmissions] = useState(false);
    const [loadingCoverage, setLoadingCoverage] = useState(false);
    const [loadingSubmissionsInTime, setLoadingSubmissionsInTime] = useState(false);
    const [locationsMapData, setLocationsMapData] = useState([]);
    const [showCustomDate, setShowCustomDate] = useState(false);
    const userData = useSelector(state => state.appReducer.userData);
    const [allCoverageSurveys, setAllcoverageSurveys] = useState([]);
    const [selectedCoverageSurveys, setSelectedCoverageSurveys] = useState([]);
    const [allCoverageSurveyFilter, setAllCoverageSurveyFilter] = useState(true);
    const [surveyCoverageModel, setSurveyCoverageModel] = useState(selectedAllSurveys);

    const dateFormat = userData?.tags?.DateFormat || constants.defaultDateFormat;
    const operationalComboFilters = [

        {
            label: 'Market',
            idKey: 'MarketId',
            options: Market
        },
        {
            label: 'Channel',
            idKey: 'LocationTypeId',
            options: Channels
        },
        {
            label: 'Distributor',
            idKey: 'DistributorId',
            options: Distributor
        },
        {
            label: 'Classification',
            idKey: 'ClassificationId',
            options: Classification
        }
    ];

    const kpiCards = [
        {
            name: 'Submissions',
            count: surveyOutlets?.TotalSubmissions
        },
        {
            name: 'Outlets with submission',
            count: surveyOutlets?.OutletCount
        },
        {
            name: 'Users with submission',
            count: surveyOutlets?.UserCount
        }
    ]

    useEffect(() => {
        let backRoute = location.pathname;
        backRoute = backRoute.split("/");
        backRoute.pop();
        backRoute = backRoute.join("/");
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute }
        });
        utils.getAnalysisComboData();
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: (
                <PageTitle titleHeading={constants.operationalDashboardTitle} titleDescription={constants.operationalDashboardDescription} classTitle="home-header-container" />
            )
        });
        return () => {
            utils.removeBackButton(dispatch);
        }
    }, []);


    useEffect(() => {
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                fromDate: defaultFromDate,
                toDate: defaultToDate,
                daysFilter: "30:day"
            }
        });
    }, [comboData, reportingCombos]);

    
    useEffect(() => {
        
        fetchSurveyFilterData()
    }, [])

    useEffect(() => {
        if(allSurveys.length > 0){
            fetchSubmissionsData();
        }
        
    }, [orderSubmissionsPer, submissionsPerOption, tempFilterModel, allSurveys]);

    useEffect(() => {
        if(allSurveys.length > 0){
           fetchCoverageData();
        }
    }, [coveragePerOption, tempFilterModel, surveyCoverageModel, allSurveys]);


    useEffect(() => {
        if(allSurveys.length > 0){
            fetchSurveyData();
        }
       
    }, [tempFilterModel, allSurveys]);

    const fetchSurveyFilterData = async () => {
        try {
            const result = await request({ url: apis.AllSurveyOperationsList, history, dispatch, jsonPayload: true });
            const surveyFilters = result?.data || [];
            setAllSurveys(surveyFilters);
            setAllcoverageSurveys(surveyFilters);
            const allSurveyIds = surveyFilters.map(survey => survey.SurveyMasterId);
            setSelectedAllSurveys(allSurveyIds);
            setSelectedCoverageSurveys(allSurveyIds);
            setSurveyCoverageModel(allSurveyIds);

        } catch (error) {
            snackbar.showError(`${t('Error fetching survey filter data', tOpts)}`);
        }
    }

    const fetchSurveyData = async () => {
        try {
            const reqParams = {
                checkedSurveys: allSurveyFilter ? allSurveys.map(survey => survey.SurveyMasterId) : selectedAllSurveys,
                startDate: dayjs(filterValues?.fromDate || defaultFromDate).format(constants.dateFormat.yymmdd),
                endDate: dayjs(filterValues?.toDate || defaultToDate).format(constants.dateFormat.yymmdd),
                isFilter: isFilter,
                MarketId: tempFilterModel.MarketId && tempFilterModel.MarketId.length > 0 ? tempFilterModel.MarketId[0] : null,
                LocationTypeId: tempFilterModel.LocationTypeId && tempFilterModel.LocationTypeId.length > 0 ? tempFilterModel.LocationTypeId[0] : null,
                ClassificationId: tempFilterModel.ClassificationId && tempFilterModel.ClassificationId.length > 0 ? tempFilterModel.ClassificationId[0] : null,
                DistributorId: tempFilterModel.DistributorId && tempFilterModel.DistributorId.length > 0 ? tempFilterModel.DistributorId[0] : null

            }
                const result = await request({ url: apis.SurveyOperationalDashboard, params: reqParams, history, dispatch, jsonPayload: true });
                setSurveyOutlets(result?.data.outlets);
                setLocationsMapData(result?.data.locationsMapData);
        } catch (error) {
            snackbar.showError(`${t('Error fetching constant survey data', tOpts)}`);
        }
    }

    const fetchSubmissionsData = async () => {
        try {
            setLoadingSubmissions(true);
            const reqParams = {
                checkedSurveys: allSurveyFilter ? allSurveys.map(survey => survey.SurveyMasterId) : selectedAllSurveys,
                optionSelected: submissionsPerOption,
                orderSelected: orderSubmissionsPer,
                startDate: dayjs(filterValues?.fromDate || defaultFromDate).format(constants.dateFormat.yymmdd),
                endDate: dayjs(filterValues?.toDate || defaultToDate).format(constants.dateFormat.yymmdd),
                MarketId: tempFilterModel.MarketId && tempFilterModel.MarketId.length > 0 ? tempFilterModel.MarketId[0] : null,
                LocationTypeId: tempFilterModel.LocationTypeId && tempFilterModel.LocationTypeId.length > 0 ? tempFilterModel.LocationTypeId[0] : null,
                ClassificationId: tempFilterModel.ClassificationId && tempFilterModel.ClassificationId.length > 0 ? tempFilterModel.ClassificationId[0] : null,
                DistributorId: tempFilterModel.DistributorId && tempFilterModel.DistributorId.length > 0 ? tempFilterModel.DistributorId[0] : null
            }
                const result = await request({ url: apis.SurveySubmissions, params: reqParams, history, dispatch, jsonPayload: true, disableLoader: true });
                setSubmissionsPerData(result?.data);
            setLoadingSubmissions(false);

        } catch (error) {
            snackbar.showError(`${t('Error fetching constant submissions data', tOpts)}`);
        }
    }

    const fetchCoverageData = async () => {
        try {
            setLoadingCoverage(true)
            const reqParams = {
                checkedSurveys: surveyCoverageModel || null,
                coverageFilter: coveragePerOption,
                StartDate: filterValues?.fromDate,
                EndDate: filterValues?.toDate,
                MarketId: tempFilterModel.MarketId && tempFilterModel.MarketId.length > 0 ? tempFilterModel.MarketId[0] : null,
                LocationTypeId: tempFilterModel.LocationTypeId && tempFilterModel.LocationTypeId.length > 0 ? tempFilterModel.LocationTypeId[0] : null,
                ClassificationId: tempFilterModel.ClassificationId && tempFilterModel.ClassificationId.length > 0 ? tempFilterModel.ClassificationId[0] : null,
                DistributorId: tempFilterModel.DistributorId && tempFilterModel.DistributorId.length > 0 ? tempFilterModel.DistributorId[0] : null
            }
                const result = await request({ url: apis.SurveyCoverage, params: reqParams, history, dispatch, jsonPayload: true, disableLoader: true });
                setSurveyCoverageData(result?.data);
            setLoadingCoverage(false)
        } catch (error) {
            snackbar.showError(`${t('Error fetching constant survey coverage data', tOpts)}`);
        }
    }


    const handleApplyClick = () => {
        const updatedTempFilterModel = { ...tempFilterModel, SurveyMasterId: selectedAllSurveys };
        setTempFilterModel(updatedTempFilterModel);
        setSurveyCoverageModel(selectedAllSurveys);
    }

    const handleCoverageApplyClick = () => {
        setSurveyCoverageModel(selectedCoverageSurveys);
    }

    const onComboFilterChange = (event, type) => {
        const value = event.target.value;

        setTempFilterModel(prevModel => {
            const newModel = {
                ...prevModel,
                [type]: value !== "all" ? [value] : null,
                startDate: prevModel?.startDate || startDateFilterModel,
                endDate: prevModel?.endDate || endDateFilterModel,
                SurveyMasterId:  selectedAllSurveys
            };
            return newModel;
        });
        setIsFilter(true);
    };

    const CombinedFilterSelect = ({ label, options, value, onChange }) => (
        <Grid item xs={1.95}>
            <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>{label}</InputLabel>
                <Select label={label} value={value} onChange={onChange}>
                    <MenuItem value="all">{t('All', tOpts)}</MenuItem>
                    {options?.map((option, key) => (
                        <MenuItem key={option?.LookupId} value={option.LookupId}>
                            {t(`${option?.DisplayValue} `, tOpts)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );

    const CustomKpiCard = ({ count, label, classes }) => (
        <Grid container justifyContent="center" alignItems="center" className={classes.kpiCardContainer}>
            <Card className={classes.kpiCard}>
                <CardContent>
                    <Typography variant="h5" component="div" align="center">
                        {count}
                    </Typography>
                    <Typography align="center" mt={1}>
                        {label}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )

    const handleAllOptionChange = (event) => {
        const isChecked = event.target.checked;
        setAllSurveyFilter(isChecked);
        if (isChecked) {
            const allSurveyIds = allSurveys.map(survey => survey.SurveyMasterId).filter(Boolean)
            setSelectedAllSurveys(allSurveyIds);
        } else {
            setSelectedAllSurveys([]);
        }
    }

    const handleCheckChange = (event) => {
        const itemSurveyId = parseInt(event.currentTarget.getAttribute('data-itemlookupid'));
        let newCheckedItems = [...selectedAllSurveys];
        if (newCheckedItems.includes(itemSurveyId)) {
            newCheckedItems = newCheckedItems.filter(id => id !== itemSurveyId);
        } else {
            newCheckedItems.push(itemSurveyId);
        }
        setSelectedAllSurveys(newCheckedItems);
        setAllSurveyFilter(newCheckedItems.length === allSurveys.length);
    };


    const handleCoverageAllOptionChange = (event) => {
        const isChecked = event.target.checked;
        setAllCoverageSurveyFilter(isChecked);
        if (isChecked) {
            const allSurveyIds = allSurveys.map(survey => survey.SurveyMasterId).filter(Boolean)
            setSelectedCoverageSurveys(allSurveyIds);
        } else {
            setSelectedCoverageSurveys([]);
        }
    }

    const handleCoverageSurveyChange = (event) => {
        const itemSurveyId = parseInt(event.currentTarget.getAttribute('data-itemlookupid'));
        let newCheckedItems = [...selectedCoverageSurveys];
        if (newCheckedItems.includes(itemSurveyId)) {
            newCheckedItems = newCheckedItems.filter(id => id !== itemSurveyId);
        } else {
            newCheckedItems.push(itemSurveyId);
        }
        setSelectedCoverageSurveys(newCheckedItems);
        setAllCoverageSurveyFilter(newCheckedItems.length === allSurveys.length);
    };

    const handlePeriodChange = (event) => {
        const { name, value } = event.target;
        const filterObj = { ...filterValues };
        filterObj.disableKeyboardInput = true;
        filterObj[name] = value;
        if (value !== constants.customDateLookUpId) {
            const obj = utils.getDateFilter(value, dateFilterMaxDate);
            filterObj[constants.fromDateString] = obj.fromDate;
            filterObj[constants.toDateString] = obj.toDate;
            setTempFilterModel(prevModel => {
                prevModel = { ...prevModel, startDate: { ...prevModel.startDate, type: 'date' }, endDate: { ...prevModel.endDate, type: 'date' } };
                const updatedStartDateModel = utils.updateTempFilterModel('startDate', filterObj.fromDate, prevModel);
                const updatedEndDateModel = utils.updateTempFilterModel('endDate', filterObj.toDate, prevModel);

                const newModel = {
                    ...prevModel,
                    ...updatedStartDateModel,
                    ...updatedEndDateModel,
                    SurveyMasterId: selectedAllSurveys
                };
                return newModel;
            });

            setShowCustomDate(false);
            dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
        } else {
            dispatch({
                type: actions.SET_FILTER_VALUES,
                filterValues: {
                    fromDate: filterObj.fromDate,
                    toDate: filterObj.toDate,
                    disableKeyboardInput: filterObj.disableKeyboardInput,
                    daysFilter: "-1",
                }
            });
            setShowCustomDate(true);
        }
    };

    const handleDateChange = (date, field) => {
        const filterObj = { ...filterValues };
        const fromDateIsAfterDate = field === constants.fromDateString && dayjs(date).isAfter(dayjs(filterObj.toDate), 'D');
        const toDateIsBeforeDate = field === constants.toDateString && dayjs(date).isBefore(dayjs(filterObj.fromDate), 'D');

        if (fromDateIsAfterDate || toDateIsBeforeDate) {
            return;
        }
        filterObj[field] = date;
        setTempFilterModel(prevModel => {
            prevModel = { ...prevModel, startDate: { ...prevModel.startDate, type: 'date' }, endDate: { ...prevModel.endDate, type: 'date' } };
            const updatedStartDateModel = utils.updateTempFilterModel('startDate', filterObj.fromDate, prevModel);
            const updatedEndDateModel = utils.updateTempFilterModel('endDate', filterObj.toDate, prevModel);

            const newModel = {
                ...prevModel,
                ...updatedStartDateModel,
                ...updatedEndDateModel,
                SurveyMasterId: selectedAllSurveys
            };

            return newModel;
        });
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
    };

    function getLabelFromValue(value) {
        const filter = utils.coverageFilters.find(filter => filter.value === value);
        return filter ? filter.label : '';
    }

    const RenderSurveyGrid = () => {
        const newArray = utils.getCustomFilterItems(tempFilterModel);
        return (
            <operationalModel.Grid additionalFilters={newArray}
            />
        );
    };
    const RenderSurveyGridMemoized = useMemo(() => <RenderSurveyGrid />, [tempFilterModel]);

    const CustomLoaderComponent = () => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} p={3}>
                <CircularProgress />
            </Box>
        );
    }

    const RenderSubmissionsPerChart = () => {
        const { categories = [], seriesData = [] } = submissionsPer || {};
        const stackedData = seriesData?.map(item => {
            return {
                name: item?.name || '',
                data: item?.data?.map(item => item.y) || [],
                color: item?.data?.map(item => item.color) || [],
            };
        });
        if (!Array.isArray(categories) || !categories.length || !Array.isArray(seriesData)) {
            return <><div className='no-map-location'>{t("No Data to Display", tOpts)}</div></>;
        }
        
        if (loadingSubmissions) {
            return <CustomLoaderComponent />
        }
        const sk = utils.getChartDimensions({data: stackedData.length || 0});
        return (
            <Paper className={classes.submissionsPerContainer}>
                <XHorizontalStackedChart
                    xLabel={'Survey'}
                    subtitle={`Submissions per ${submissionsPerOption}`}
                    categories={categories || []}
                    series={seriesData || []}
                    data={stackedData}
                    orientation="horizontal"
                    loading={true}
                    margin={{ left: sk.margin.left, right: 25 }}
                />
            </Paper>
        )
    }
    const RenderSubmissionsPerChartMemoized = useMemo(() => <RenderSubmissionsPerChart />, [submissionsPer, loadingSubmissions]);

    const RenderSubmissionsInTimeChart = () => {
        const { categories = [], seriesData = [] } = surveySubmissionsInTimeData || {};
        if (loadingSubmissionsInTime) {
            return <CustomLoaderComponent />
        }
        return (
            <Paper className={classes.coverageContainer}>
                <MultiLineChart
                    subtitle={''}
                    categories={categories}
                    series={seriesData}
                    type={'datetime'}
                    custom={true}
                    chartType={'line'}
                    format={format}
                />              
            </Paper>
        )
    }

    const RenderCoveragePerChart = () => {
        const { categories = [], seriesData = [] } = surveyCoverageData || {};
        let sortedData = [];
        if (Array.isArray(seriesData) && seriesData.length > 0 && Array.isArray(seriesData[0].data)) {
            sortedData = seriesData[0].data.sort((a, b) => b.percentage - a.percentage);
        }
        const sortedCategories = sortedData?.map(entry => entry.name) || [];
        const xBarData = sortedData?.map(item => item.percentage) || [];
        if (!Array.isArray(categories) || !categories.length || !Array.isArray(seriesData)) {
            return <><div className='no-map-location'>{t("No Data to Display", tOpts)}</div></>;
        }
        
        if (loadingCoverage) {
            return <CustomLoaderComponent />
        }
        return (
            <Paper className={classes.coverageContainer}>
                <XBarChart
                    subtitle={''}
                    categories={sortedCategories || []}
                    data={xBarData || []}
                    orderBy="descending"
                    reorderCategories={true}
                    xLabel={getLabelFromValue(coveragePerOption)}
                />
            </Paper>
        );
    }
    const RenderCoveragePerChartMemoized = useMemo(() => <RenderCoveragePerChart />, [surveyCoverageData, loadingCoverage]);
    const RenderMemoizedMap = useMemo(() => <SurveyMap locations={locationsMapData} t={t} tOpts={tOpts} classes={classes} />, [locationsMapData]);
    return (
        <Grid spacing={3} xs={12}>
            <Paper className={classes.customPaper}>
                <Grid container spacing={2}>
                    <Grid item xs={1.95}>
                        <MultipleSurveyFilter classes={classes} handleChange={handleCheckChange} handleAllOptionChange={handleAllOptionChange} handleApplyClick={handleApplyClick} data={allSurveys} isAllSelected={allSurveyFilter} selectedSurveys={selectedAllSurveys} t={t} tOpts={tOpts} />
                    </Grid>
                    <Grid item xs={1.95}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel>{t('Time Period', tOpts)}</InputLabel>
                                <Select label={t('Time Period', tOpts)} name="daysFilter" value={filterValues?.daysFilter || ''} onChange={handlePeriodChange}>
                                    {utils.daysDropDown?.map((option, key) => (
                                        <MenuItem key={option?.LookupId} value={option.LookupId}>
                                            {t(`${option?.DisplayValue} `, tOpts)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                    {operationalComboFilters.map((filter, f) => {
                        const { label, idKey, options } = filter;
                        return (
                            <CombinedFilterSelect key={f} label={t(label, tOpts)} options={options} value={tempFilterModel && tempFilterModel[idKey] ? tempFilterModel[idKey] : 'all'} onChange={(e) => onComboFilterChange(e, idKey)} />
                        );
                    })}
                </Grid>
                {showCustomDate ?
                    <Grid>
                        <Grid container spacing={1} xs={4}>
                            <Grid item xs={5} sm={5} md={5} lg={5} className="">
                                <KeyboardDatePicker
                                    id="date-picker-dialog-1"
                                    className='w-100'
                                    margin="normal"
                                    required
                                    label={t("Start Date", tOpts)}
                                    name="fromDate"
                                    format={dateFormat}
                                    value={filterValues.fromDate}
                                    onChange={(e) => handleDateChange(e, constants.fromDateString)}
                                    KeyboardButtonProps={{ "aria-label": "change date" }}
                                    disableFuture={true}
                                    invalidDateMessage={t("Invalid Date Format", tOpts)}
                                    minDateMessage={t('Date should not be before minimum date', tOpts)}
                                    maxDateMessage={t('Date should not be after maximum date', tOpts)}
                                    maxDate={filterValues.toDate || (filterValues.toDate ? filterValues.toDate : new Date())} 
                                    inputProps={{
                                        disabled: filterValues.disableKeyboardInput,
                                    }} />
                                {filterValues.fromDate ? '' : <span className='date-picker-validation-style'>{`${t('Start Date is required', tOpts)} *`}</span>}
                            </Grid>
                            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} className="mt-4 pt-3 text-center">{t('To', tOpts)}</Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} className="">
                                <KeyboardDatePicker
                                    id="date-picker-dialog-2"
                                    className='w-100'
                                    required
                                    margin="normal"
                                    name={constants.toDateString}
                                    label={t("End Date", tOpts)}
                                    format={dateFormat}
                                    value={filterValues.toDate}
                                    invalidDateMessage={t("Invalid Date Format", tOpts)}
                                    minDateMessage={t('Date should not be before minimum date', tOpts)}
                                    maxDateMessage={t('Date should not be after maximum date', tOpts)}
                                    onChange={(e) => handleDateChange(e, constants.toDateString)}
                                    KeyboardButtonProps={{ "aria-label": "change date" }}
                                    disableFuture={true}
                                    minDate={filterValues.fromDate ? filterValues.fromDate : new Date()}
                                    maxDate={new Date()} 
                                    inputProps={{
                                        disabled: filterValues.disableKeyboardInput,
                                    }} />
                                {filterValues.toDate ? '' : <span className='date-picker-validation-style'>{`${t('End date is required', tOpts)} *`}</span>}
                            </Grid>
                        </Grid>
                    </Grid>
                    : null}
            </Paper>
            <Grid container spacing={3} className={classes.kpiContainer}>
                {kpiCards.map((card, c) => {
                    const { name, count } = card;
                    return (
                        <Grid item key={c}>
                            <CustomKpiCard count={count || 0} label={t(name, tOpts)} classes={classes} />
                        </Grid>
                    );
                })}
            </Grid>

            <Grid container>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Paper p={1}>
                            {RenderSurveyGridMemoized}
                        </Paper>
                        <Paper className={classes.chartPaper}>
                            <Typography variant="h6" m={1}>{t('Coverage % per', tOpts)}</Typography>
                            <Grid container p={2} spacing={2} justifyContent="space-between">
                                <Grid item xs={7}>
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={utils.coverageFilters}
                                        value={coveragePerOption}
                                        t={t}
                                        tOpts={tOpts}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                                setCoveragePerOption(newValue);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <MultipleSurveyFilter classes={classes} handleChange={handleCoverageSurveyChange} handleAllOptionChange={handleCoverageAllOptionChange} handleApplyClick={handleCoverageApplyClick} data={allCoverageSurveys} isAllSelected={allCoverageSurveyFilter} selectedSurveys={selectedCoverageSurveys} t={t} tOpts={tOpts} />
                                </Grid>
                            </Grid>
                            <Grid container>
                                {RenderCoveragePerChartMemoized}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper p={1} className={classes.submissionsPaper}>
                            <Typography variant="h6" m={1}>{t('Submissions Per', tOpts)}</Typography>
                            <Grid container direction="row" sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                                <Grid item>
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={utils.submissionsPerFilters}
                                        value={submissionsPerOption}
                                        t={t}
                                        tOpts={tOpts}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                                setSubmissionsPerOption(newValue);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={utils.orderSubmissionsPer}
                                        value={orderSubmissionsPer}
                                        t={t}
                                        tOpts={tOpts}
                                        onChange={(event, newValue) => { setOrderSubmissionsPer(newValue) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                {RenderSubmissionsPerChartMemoized}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.mapContainer}>
                <Paper className={classes.mapContainer}>
                    {RenderMemoizedMap}
                </Paper>
            </Grid>
        </Grid>
    )
}

const CustomToggleButtonGroup = ({ label, values, value, onChange, t, tOpts }) => (
    <Grid container>
        {label ? <Grid item>
            <Typography variant="h6">{t(label, tOpts)}</Typography>
        </Grid> : <></>}
        <Grid item ml={2}>
            <ToggleButtonGroup
                size="small"
                value={value}
                exclusive
                onChange={onChange}
                aria-label="toggle-button-group"
            >
                {values.map((val) => (
                    <ToggleButton key={val.value} value={val.value} aria-label={val.label}>
                        {t(val.label, tOpts)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Grid>
    </Grid>
);

const MultipleSurveyFilter = ({ classes, handleChange, handleAllOptionChange, handleApplyClick, data, isAllSelected, selectedSurveys, t, tOpts }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const onApplyClick = () => {
        setIsMenuOpen(false);
        handleApplyClick()
    };
    return (
        <FormControl variant="outlined" fullWidth size="small" className={classes.surveyFilter}>
            <InputLabel>{t("Survey", tOpts)}</InputLabel>
            <Select
                label={t("Survey", tOpts)}
                className={classes.surveySelectContainer}
                multiple
                MenuProps={{
                    open: isMenuOpen, // Explicitly bind open state
                    onClose: () => setIsMenuOpen(false), // Ensure it closes when needed
                }}
                IconComponent={() => (
                    !isMenuOpen ? (
                        <ArrowDropDown onClick={() => setIsMenuOpen(true)} />
                    ) : (
                        <ArrowDropUp onClick={() => setIsMenuOpen(false)}/>
                    )
                )}
                value={isAllSelected ? ['All'] : selectedSurveys}
                renderValue={(selectedKeys) =>
                    isAllSelected ? t('All', tOpts) :
                        selectedKeys
                            .map((key) =>
                                data.find((survey) => survey.SurveyMasterId === key)?.SurveyName || key
                            )
                            .join(", ")
                }
                onOpen={() => setIsMenuOpen(true)}
            >
                <MenuItem value={'All'} onClick={(e) => { e.stopPropagation(); handleAllOptionChange({ target: { checked: !isAllSelected } }); }}>
                    <Checkbox checked={isAllSelected} onChange={(e) => handleAllOptionChange(e)} />
                    <IconButton>
                        <FiberManualRecord />
                    </IconButton>
                    <ListItemText primary={t(`${'All'} `, tOpts)} />
                </MenuItem>
                {data?.map((survey, key) => (
                    <MenuItem key={survey?.SurveyMasterId} data-itemlookupid={survey?.SurveyMasterId} onClick={handleChange}>
                        <Checkbox checked={isAllSelected || selectedSurveys.includes(survey?.SurveyMasterId)} />
                        <IconButton color={survey.SurveyStatus ? 'success' : 'error'}>
                            <FiberManualRecord />
                        </IconButton>
                        <ListItemText primary={t(`${survey?.SurveyName} `, tOpts)} />
                    </MenuItem>
                ))}
                <Paper className={classes.stickyButtonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onApplyClick();
                            setIsMenuOpen(false);
                        }}
                        className={classes.surveyApplyBtn}
                    >
                        {t("Apply", tOpts)}
                    </Button>
                </Paper>
            </Select>
        </FormControl>
    )
}

export default SurveyOperationalDashboard;