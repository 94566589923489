import { getGridStringOperators, getGridNumericOperators } from '@mui/x-data-grid-premium';
import UiModel from './ui-model';
import constants from '../utils/constants';

const stringFilterOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');
const numberFilterOperators = getGridNumericOperators().filter((operator) => operator.value === '=');

const alertOverviewLocationModel = new UiModel({
    title: "Alert Overview Location",
    defaultSort: 'RowNumber ASC',
    titleDescription: 'Check and oversee your current open alerts',
    idProperty: 'RowNumber',
    api: "Location",
    updatePageTitle: false,
    module: "Operations",
    controllerType: "cs",
    addCreatedModifiedColumns: false,
    preferenceId: 'playbookAlertOverviewLocation',
    modelPermissions: { add: false, edit: false, export: false, delete: false, filter: true, resolve: false, assign: true },
    actionWidth: 80,
    hideSubTitle: true,
    isCustomActionsGrid: true,
    fieldsToRemoveFromFilter: ['Latitude', 'Longitude'],
    addFieldToPayload: ['Latitude', 'Longitude'],
    defaultPayloadFilter: [{ field: 'GetDistanceFilteredLocation', type: 'boolean', value: true }],
    defaultFilters: [{ field: "IsActive", type: "boolean", value: 'true' }],
    columns: [
        { field: "Code", width: 100, label: "Code", filterOperators: stringFilterOperators, type: 'string' },
        { field: "IsActive", width: 100, label: "Active", type: 'boolean', sortable: false, hide: true },
        { field: "Name", type: 'string', width: 180, label: "Location Name", filterOperators: stringFilterOperators },
        { field: "FullAddress", width: 100, label: "Address", filterOperators: stringFilterOperators, type: 'string' },
        { field: "City", width: 150, label: "City", filterOperators: stringFilterOperators, type: 'string' },
        { field: "State", width: 100, label: "State", filterOperators: stringFilterOperators, type: 'string' },
        { field: "PostalCode", width: 150, label: "Postal Code", filterOperators: stringFilterOperators, type: 'string' },
        {
            field: "Latitude", width: 160, label: "Latitude/Longitude", filterable: false, renderCell: (params) => {
                const { Latitude, Longitude } = params.row;
                return `${Latitude}/${Longitude}`;
            }
        },
        {
            field: "Distance", label: "Distance", width: 150, filterable: false, sortable: false, filterOperators: numberFilterOperators, renderCell: (params) => {
                let { value } = params;
                const { SearchUnitId } = params.row;
                if (value) {
                    if (SearchUnitId === constants.DistanceUnits.Miles) {
                        const conversionFactor = 0.621371
                        value = (value * conversionFactor).toFixed(2);
                    } else {
                        value = value.toFixed(2);
                    }
                } else {
                    value = 0;
                }
                return `${value} ${SearchUnitId === constants.DistanceUnits.Miles ? 'Miles' : 'Kms'}`;
            }
        }
    ]
});

export default alertOverviewLocationModel;