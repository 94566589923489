import { Box, Grid, Paper, styled } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useEffect, useState, useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { apis } from "../../httpUtil";
import { transport } from "../../httpUtil/httpRequest";
import actions from '../../redux/actions';
import utils from "../../utils";
import SalesVelocity from "../../assets/images/score-card/SalesVelocity.svg";
import ProductAvailability from "../../assets/images/score-card/ProductAvailability.svg";
import Merchandising from "../../assets/images/score-card/Merchandising.svg";
import Iot from "../../assets/images/score-card/iot.svg";
import { PageTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "@durlabh/dframework-ui";


// Constants
const THEME_COLORS = {
  primary: '#13316C',
  background: '#D9D9D9',
  border: '#144230',
};

const ICONS = {
  'cash-register': SalesVelocity,
  'search-minus': ProductAvailability,
  'bottles': Merchandising,
  'location': Iot,
};

// Styled Components
const CategoryContainer = styled(Paper)(({ theme, isEven }) => ({
  padding: theme.spacing(2),
  backgroundColor: isEven ? '#F2F2F2' : THEME_COLORS.background,
  width: '100%'
}));

const MetricCard = styled(Paper)(({ theme }) => ({ 
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${THEME_COLORS.border}`,
  borderRadius: '8px',
  background: 'transparent',
  textAlign: 'center',
}));

const CategorySection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& .MuiTypography-root': {
    marginLeft: theme.spacing(2),
  },
}));

const KPIGrid = styled(Grid)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .MuiPaper-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const MainContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '100%',
  boxSizing: 'border-box'
}));

const HeaderGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
  '& .MuiTypography-h5': {
    color: THEME_COLORS.primary,
    fontWeight: 500,
  },
}));


// Helper Components
const MetricDisplay = memo(({ metric }) => (
  <Grid item xs={12} sm={6} md={3}>
    <MetricCard elevation={0}>
      <Typography 
        variant="subtitle2" 
        sx={{ 
          mb: 1,
          minHeight: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: THEME_COLORS.primary
        }}
      >
        {metric.title}
      </Typography>
      <Typography 
        variant="h6" 
        sx={{ 
          mt: 'auto',
          mb: 'auto',
          fontWeight: 500,
          color: THEME_COLORS.primary
        }}
      >
        {metric.value}
      </Typography>
    </MetricCard>
  </Grid>
));

// Main Component
const ScoreCard = () => {

	const { t: trans, i18n } = useTranslation();
    const tOpts = { t: trans, i18n };
    const t = utils.t;

	const INITIAL_STATE = {
		categories: [
			{
				id: 1,
				icon: "cash-register",
				title: t("Sales Velocity", tOpts),
				description: t("The speed at which products are sold (how quickly do they move off the shelve) within a defined time frame", tOpts),
				metrics: [
					{
						title: t("Average number of Units Sold Per Door Opening all Assets", tOpts),
						value: "0"
					},
					{
						title: t("Average number of Units Sold Daily Per Asset", tOpts),
						value: "0"
					},
					{
						title: t("Average monthly Revenue Per Asset", tOpts),
						value: "0"
					},
					{
						title: t("Total monthly Revenue for all Assets", tOpts),
						value: "0"
					}
				]
			},
			{
				id: 2,
				icon: "search-minus",
				title: t("Product availability & Missed opportunity analysis", tOpts),
				description: t("Out of stock/empty spaces and missed revenue calculation", tOpts),
				metrics: [
					{
						title: t("Average number of out of stock days per Product", tOpts),
						value: "0"
					},
					{
						title: t("Average monthly Missed Revenue Per Asset", tOpts),
						value: "0"
					},
					{
						title: t("Total monthly Missed Revenue for all Assets", tOpts),
						value: "0"
					},
					{
						title: t("Missed Sales Uplift", tOpts),
						value: "0%"
					}
				]
			},
			{
				id: 3,
				icon: "bottles",
				title: t("Cooler products selection", tOpts),
				description: t("Target portfolio compliance, Planogram compliance, Own vs. Foreign Share of Shelf", tOpts),
				metrics: [
					{
						title: `${t("Own Share of Shelf", tOpts)} (%)`,
						value: "0"
					},
					{
						title: `${t("Foreign Share of Shelf", tOpts)} (%)`,
						value: "0"
					},
					{
						title: `${t("Empty Share of Shelf", tOpts)} (%)`,
						value: "0"
					},
					{
						title: `${t("Target portfolio compliance", tOpts)} (%)`,
						value: "0"
					}
				]
			},
			{
				id: 4,
				icon: "location",
				title: t("Cooler Controller data", tOpts),
				description: t("Location, temperature, on/off time, door openings", tOpts),
				metrics: [
					{
						title: t("Average Daily Door Openings per Asset", tOpts),
						value: "0"
					},
					{
						title: t("Average monthly Replenishment frequency per Asset", tOpts),
						value: "0"
					},
					{
						title: `${t("Geo-fence violation alerts", tOpts)} (%)`,
						value: "0%"
					},
					{
						title: `${t("High temperature alerts", tOpts)} (%)`,
						value: "0%"
					}
				]
			}
		]
	};
	const [dashboardData, setDashboardData] = useState(INITIAL_STATE);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const handleApiError = useCallback((error) => {
		const errorMessage = error?.response?.data?.message || error.message || "An error occurred";
		setError(errorMessage);
		snackbar.showError(t("Something went wrong, please try again.", tOpts));
	}, []);

	const transformResponseData = useCallback((responseData) => {
		if (!responseData) return INITIAL_STATE;

		return {
			categories: [
			{
				id: 1,
				icon: "cash-register",
				title: t("Sales Velocity", tOpts),
				description: t("The speed at which products are sold (how quickly do they move off the shelve) within a defined time frame", tOpts),
				metrics: [
				{
					title: t("Average number of Units Sold Per Door Opening all Assets", tOpts),
					value: responseData?.velocity_and_availability.AverageNumberOfUnitsSoldDoorOpeningAllAsset ?? 0
				},
				{
					title: t("Average number of Units Sold Daily Per Asset", tOpts),
					value: responseData?.velocity_and_availability.AverageDailyUnitsSoldPerAsset ?? 0
				},
				{
					title: t("Average monthly Revenue Per Asset", tOpts),
					value: responseData?.velocity_and_availability.AverageMonthlyRevenuePerAsset ?? 0
				},
				{
					title: t("Total monthly Revenue for all Assets", tOpts),
					value: responseData?.velocity_and_availability.TotalRevenueAllAsset ?? 0
				}
				]
			},
			{
				id: 2,
				icon: "search-minus",
				title: t("Product availability & Missed opportunity analysis", tOpts),
				description: t("Out of stock/empty spaces and missed revenue calculation", tOpts),
				metrics: [
				{
					title: t("Average number of out of stock days per Product", tOpts),
					value: responseData?.velocity_and_availability.AverageOutOfStockDaysPerProduct ?? 0
				},
				{
					title: t("Average monthly Missed Revenue Per Asset", tOpts),
					value: responseData?.velocity_and_availability.AverageMonthlyMissedRevenuePerAsset ?? 0
				},
				{
					title: t("Total monthly Missed Revenue for all Assets", tOpts),
					value: responseData?.velocity_and_availability.TotalMissedRevenue ?? 0
				},
				{
					title: t("Missed Sales Uplift", tOpts),
					value: `${responseData?.velocity_and_availability.MissedSalesUplift ?? 0}%`
				}
				]
			},
			{
				id: 3,
				icon: "bottles",
				title: t("Cooler products selection", tOpts),
				description: t("Target portfolio compliance, Planogram compliance, Own vs. Foreign Share of Shelf", tOpts),
				metrics: [
				{
					title: `${t("Own Share of Shelf", tOpts)} (%)`,
					value: responseData?.selection.OwnShareOfShelf ?? 0
				},
				{
					title: `${t("Foreign Share of Shelf", tOpts)} (%)`,
					value: responseData?.selection.ForeginShareOfShelf ?? 0
				},
				{
					title: `${t("Empty Share of Shelf", tOpts)} (%)`,
					value: responseData?.selection.EmptyShareOfShelf ?? 0
				},
				{
					title: `${t("Target portfolio compliance", tOpts)} (%)`,
					value: responseData?.selection.TargetPortfolioCompliance ?? 0
				}
				]
			},
			{
				id: 4,
				icon: "location",
				title: t("Cooler Controller data", tOpts),
				description: t("Location, temperature, on/off time, door openings", tOpts),
				metrics: [
				{
					title: t("Average Daily Door Openings per Asset", tOpts),
					value: responseData?.iot.AverageDailyDoorOpeningsPerAsset ?? 0
				},
				{
					title: t("Average monthly Replenishment frequency per Asset", tOpts),
					value: responseData?.iot.AverageMonthlyReplenishmentFrequencyPerAsset ?? 0
				},
				{
					title: `${t("Geo-fence violation alerts", tOpts)} (%)`,
					value: `${responseData?.iot.TemperatureAlertPercentage ?? 0}%`
				},
				{
					title: `${t("High temperature alerts", tOpts)} (%)`,
					value: `${responseData?.iot.GPSDisplacementAlertPercentage ?? 0}%`
				}
				]
			}
			]
		};
	}, []);

	const fetchData = useCallback(async (signal) => {
		dispatch({ type: "UPDATE_LOADER_STATE", loaderOpen: true });
		
		try {
		const params = {
			url: apis.ScoreCard,
			method: "GET",
			headers: { "Content-Type": "application/json" },
			credentials: "include",
			signal
		};

		const response = await transport(params);
		
		if (!response.data || Object.keys(response.data).length === 0) {
			snackbar.showError(t("No Data", tOpts));
		}

		setDashboardData(transformResponseData(response.data));
		} catch (error) {
		if (error.name !== 'AbortError') {
			handleApiError(error);
		}
		} finally {
		dispatch({ type: "UPDATE_LOADER_STATE", loaderOpen: false });
		}
	}, [dispatch, handleApiError, transformResponseData]);

	useEffect(() => {
		dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: (
                <PageTitle
                    icon=""
                    titleHeading={t("Business Value & Relevance (Beverages)", tOpts)}
                    titleDescription={t("Core KPI's that we are measuring and use cases we are validating", tOpts)}
                    title={t("Business Value & Relevance (Beverages)", tOpts)}
                />
            ),
        });
		const controller = new AbortController();
		fetchData(controller.signal);
		return () => controller.abort();
	}, [fetchData]);

	if (error) {
		return (
		<Box sx={{ p: 3, textAlign: 'center' }}>
			<Typography color="error">{error}</Typography>
		</Box>
		);
	}

	return (
		<Box sx={{ width: '100%' }}>
		<MainContainer>
			<HeaderGrid container spacing={2}>
			<Grid item xs={4} alignItems="center">
				<Typography variant="h5" sx={{ pl: 2, textAlign: "center" }}>
				Category
				</Typography>
			</Grid>
			<Grid item xs={8}>
				<Typography variant="h5" sx={{ textAlign: "center" }}>
				KPIs
				</Typography>
			</Grid>
			</HeaderGrid>

			{dashboardData.categories.map((category) => (
			<CategoryContainer key={category.id} elevation={0} isEven={category.id % 2 === 0}>
				<Grid container spacing={2} sx={{ minHeight: '120px', width: '100%', m: 0 }}>
				<Grid item xs={12} md={4}>
					<CategorySection>
					<img 
						src={ICONS[category.icon]} 
						alt={category.title} 
						height={100} 
						width={100}
					/>
					<Box>
						<Typography variant="h6" sx={{ mb: 1 }}>
						{category.title}
						</Typography>
						<Typography 
						variant="body2" 
						color="textSecondary" 
						sx={{ lineHeight: 1.4 }}
						>
						{category.description}
						</Typography>
					</Box>
					</CategorySection>
				</Grid>
				
				<Grid item xs={12} md={8}>
					<KPIGrid container spacing={2}>
					{category.metrics.map((metric, index) => (
						<MetricDisplay key={index} metric={metric} />
					))}
					</KPIGrid>
				</Grid>
				</Grid>
			</CategoryContainer>
			))}
		</MainContainer>
		</Box>
	);
};

export default memo(ScoreCard);