import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Grid } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import OutlinedInput from '@mui/material/OutlinedInput';
import utils from '../../utils';

const RenderHeaderFilters = ({ model, onHandleChange, values, t, tOpts }) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 224,
                width: 250,
            },
        },
    };
    return model?.externalHeaderFilters?.map(ele => {
        const { field, label, fieldType, width, operator, options, type } = ele;
        switch (fieldType) {
            case 'string':
                return <TextField style={{ marginRight: 10 }} id={field} label={t(label, tOpts)} value={values[field]} variant="outlined" name={field} onChange={(e) => onHandleChange(e, operator, type)} />
            case 'singleSelect':
                return <Box sx={{ marginRight: 1, width: width || 100 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                        <Select labelId="demo-simple-select-label" id={field} value={values[field]} label={label} autoWidth name={field} onChange={(e) => onHandleChange(e, operator, type)} >
                            {options.map((option) => {
                                return <MenuItem value={option.value}>{t(option.label, tOpts)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
            case 'multiSelect':
                return <Box sx={{ marginRight: 1, width: width || 100 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t(label, tOpts)}</InputLabel>
                        <Select labelId="demo-multiple-name-label" id={field} value={values[field] || []} label={label} autoWidth name={field} onChange={(e) => onHandleChange(e, operator, type, true)} input={<OutlinedInput label={label} />} MenuProps={MenuProps} multiple >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value} >
                                    {t(option.label, tOpts)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            default:
                return <TextField style={{ marginRight: 10 }} id={field} label={t(label, tOpts)} value={values[field]} variant="outlined" name={field} onChange={(e) => onHandleChange(e, operator, type)} />
        }
    })
}

export default function HeaderFilters({ model, onHandleChange, values, onApplyClick, onResetClick }) {
    const t = utils.t
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    return (
        <>
            <Grid item xs={10} sm={10} md={10} lg={10} style={{ display: 'flex' }}>
                <RenderHeaderFilters model={model} onHandleChange={onHandleChange} values={values} t={t} tOpts={tOpts} />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} style={{ display: 'flex', justifyContent: 'end' }}>
                <Button style={{ margin: 10 }} variant="contained" onClick={() => onApplyClick()}>{t("Apply", tOpts)}</Button>
                <Button style={{ margin: 10 }} variant="contained" onClick={() => onResetClick()}>{t("Reset", tOpts)}</Button>
            </Grid>
        </>
    )
};