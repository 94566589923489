import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { apis } from "../../httpUtil";
import { transport } from "../../httpUtil/httpRequest";
import {
    Box,
    Grid,
    Paper,
    styled,
    TextField,
    Button,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Card,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import utils from "../../utils";
import { useSnackbar } from "@durlabh/dframework-ui";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
        backgroundColor: "#1A2027",
    }),
}));

const EvenDepletionGuide = () => {
    const [data, setData] = useState([]);
    const [cabinetCapacity, setCabinetCapacity] = useState({
        shelves: 5,
        facings: 5,
        deep: 5,
    });
	const snackbar = useSnackbar();
	const [errors, setErrors] = useState({
		shelves: false,
		facings: false,
		deep: false,
	});
    const [occupancy, setOccupancy] = useState({
		productOccupancy: [],
		cabinetOccupancy: []
	});
    const dispatch = useDispatch();
	const { t: trans, i18n } = useTranslation();
    const tOpts = { t: trans, i18n };
    const t = utils.t;
    useEffect(() => {
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: (
                <PageTitle
                    icon=""
                    titleHeading={t("Even Depletion Guide", tOpts)}
                    titleDescription={t("Optimise bestselling SKU’s to avoid OOS events between visits", tOpts)}
                    title={t("Even Depletion Guide", tOpts)}
                />
            ),
        });
        getData();
    }, []);

    const fillCabinet = (productOccupancy) => {
        const shelves = Number(cabinetCapacity.shelves);
        const facings = Number(cabinetCapacity.facings);
        // Initialize empty cabinet
        const cabinet = Array(shelves)
            .fill()
            .map(() => Array(facings).fill(null));

        let currentShelf = 0;
        let currentFacing = 0;
        const totalCapacity = shelves * facings;
        let usedCapacity = 0;

        // Try to place each product
        for (const product of productOccupancy) {
            // Calculate how much of this product we can fit
            const remainingCapacity = totalCapacity - usedCapacity;
            const spaceToUse = Math.min(
                remainingCapacity,
                product.ProductOccupancy
            );

            if (spaceToUse === 0) {
                break; // Cabinet is full
            }

            // Place the product
            let placedCount = 0;
            while (placedCount < spaceToUse && currentShelf < shelves) {
                cabinet[currentShelf][currentFacing] = product;
                placedCount++;
                usedCapacity++;

                // Move to next position
                currentFacing++;
                if (currentFacing >= facings) {
                    currentFacing = 0;
                    currentShelf++;
                }
            }
        }
        setOccupancy({
			productOccupancy: productOccupancy.filter(x => x.ProductOccupancy > 0),
			cabinetOccupancy: cabinet
		})
    };

	const validateInput = (value) => {
        return !isNaN(value) && Number(value) > 0 && Number.isInteger(Number(value));
    };

	const updateCabinetCapacity = (e, key) => {
        const value = e.target.value;
        if(value.includes(".")) return;
        setCabinetCapacity(prev => ({
            ...prev,
            [key]: value
        }));

        setErrors(prev => ({
            ...prev,
            [key]: !validateInput(value)
        }));
    };

    const getData = async () => {
        dispatch({ type: "UPDATE_LOADER_STATE", loaderOpen: true });
        try {
			const params = {
				url: apis.EvenDepletionGuide,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
			};
			const response = await transport(params);
			if (response.data?.result?.length) {
				setData(response.data.result);
			}
			else {
				snackbar.showError(t("No Data", tOpts))
			}
		}
		catch (error) { 
			snackbar.showError(t("Something went wrong, please try again.", tOpts))
		}
		finally {
			dispatch({ type: "UPDATE_LOADER_STATE", loaderOpen: false });
		}       
    };

    const calculateProductOccupancy = () => {
        const shelves = Number(cabinetCapacity.shelves);
        const facings = Number(cabinetCapacity.facings);
        const deep = Number(cabinetCapacity.deep);
        const calucatedProductOccupancy = data.map((row) => {
            row.ProductOccupancy = Math.round(
                (row.WeeklySales / (shelves * facings * deep)) *
                    (shelves * facings)
            );
            return row;
        });
		fillCabinet(calucatedProductOccupancy);
    };


    useEffect(() => {
        calculateProductOccupancy();
    }, [data]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>                       
                        <div style={{ display: "flex", marginTop: 20, marginBottom: 20 }}>
                            <Grid item xs={6}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
									<Typography variant="h6" alignContent={"center"} style={{marginRight: 10}}>{t("Shelves", tOpts)}: </Typography>
                                    <TextField
                                        label={errors.shelves ? t("Must be greater than 0", tOpts) : t("Shelves", tOpts) }
                                        variant="outlined"
                                        value={cabinetCapacity.shelves}
										error={errors.shelves}
                                        onChange={(e) => updateCabinetCapacity(e, 'shelves')}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<Typography variant="h6" alignContent={"center"} style={{marginRight: 10}}>{t("Facings", tOpts)}: </Typography>
                                    <TextField
                                        label={errors.facings ? t("Must be greater than 0", tOpts) : t("Facings", tOpts)}
                                        variant="outlined"
                                        value={cabinetCapacity.facings}
										error={errors.facings}
                                        onChange={(e) => updateCabinetCapacity(e, 'facings')}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<Typography variant="h6" alignContent={"center"} style={{marginRight: 10}}>{t("Depth", tOpts)}: </Typography>
                                    <TextField
                                        label={errors.deep ? t("Must be greater than 0", tOpts) : t("Depth", tOpts)}
                                        variant="outlined"
                                        value={cabinetCapacity.deep}
										error={errors.deep}
                                        onChange={(e) => updateCabinetCapacity(e, 'deep')}										
                                    />
                                </div>
                            </Grid>
							<Grid item xs={6}>
								<div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
								<Button
                                variant="contained"
                                className="background-theme-blue"
                  				color="primary"
                                onClick={calculateProductOccupancy}
								disabled={errors.shelves || errors.facings || errors.deep}
                            >
                                {t("Apply", tOpts)}
                            </Button>
                                </div>
                            </Grid>
                        </div>
                    </Item>
                </Grid>
            </Grid>
            <div style={{ marginTop: 20, display: "flex", maxHeight: 600 }}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Item>
                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell>Product Occupancy</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {occupancy.productOccupancy.map((row) => (
                                        <TableRow
                                            key={row.ProductId}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.Product}
                                            </TableCell>
                                            <TableCell>
                                                {row.ProductOccupancy}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Item>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} marginLeft={2} style={{maxWidth: 1000, overflowY: 'auto', overflowX: 'auto'}}>
                    {occupancy.cabinetOccupancy.map((shelf, i) => (
                        <div key={`parent-shelf-${i}`} style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "inline-flex", overflow: "auto" }} key={`shelf_${i}`}>
                            {shelf?.map((product, j) => 
							    product ? (
									<img
										src={
											apis.productThumbnail +
											"/" +
											product.ProductId +
											".png"
										}
										key={j}
										className="m-2"
										style={{borderRadius: 10, width: 100, height: 100}}
										alt={product.Product}
									/>
								) : (
									<Card style={{borderRadius: 10, width: 100, height: 100}} className="m-2" key={j}></Card>
								)
                            )}
                        </div>
						<hr className="hr-line" />
						</div>
                    ))}
                </Grid>
            </div>
        </Box>
    );
};

export default EvenDepletionGuide;
